import { Md5 } from "ts-md5";
import {
  createEffect,
  createSignal,
  Match,
  Show,
  Switch,
  type JSX,
  type Setter,
  useContext,
} from "solid-js";
import { Link, NavLink } from "@solidjs/router";
import { useI18n } from "@solid-primitives/i18n";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuItem,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "solid-headless";
import { Icon } from "solid-heroicons";
import { bars_3, bell, xMark } from "solid-heroicons/outline";
import { UserContext } from "./UserContext";
import { Logo } from "./Logo";
import { User } from "../helpers/types";

const user = useContext(UserContext);

/*
type NavigationType = {
  hr?: boolean;
  href: string;
  name: string;
};

const navigation: NavigationType[] = [
  { name: "Dashboard", href: "/dashboard" },
  { name: "Annotation", href: "/annotation" },
  { name: "Statistics", href: "/statistics" },
];

const userNavigation: NavigationType[] = [
  { name: "Your Profile", href: "/userprofile" },
  { name: "Settings", href: "/settings" },
  { name: "Sign out", href: "/sign-out", hr: true },
];
*/

function Bars3Icon() {
  return <Icon aria-hidden="true" class="block h-6 w-6" path={bars_3} />;
}

function BellIcon() {
  return <Icon aria-hidden="true" class="h-6 w-6" path={bell} />;
}

function XMarkIcon() {
  return <Icon aria-hidden="true" class="block h-6 w-6" path={xMark} />;
}

interface HeaderNavRowProps {
  user: User | undefined;
}

function HeaderNavRow(props: HeaderNavRowProps) {
  const [t] = useI18n();

  return (
    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pt-2 pb-3">
        <NavButton href="/dashboard" title={t("dashboard", {}, "Dashboard")} />
        <NavButton
          href="/annotation"
          title={t("annotation", {}, "Annotation")}
        />
        {/*
          <NavButton
            href="/statistics"
            title={t("statistics", {}, "Statistics")}
          />
        */}
      </div>

      <div class="border-t border-gray-200 pt-4 pb-3">
        <div class="flex items-center px-4">
          <UserInfo user={props.user} />
          {/*
            <NotificationButton />
          */}
        </div>

        <div class="mt-3 space-y-1">
          {/*
            <MenuButton
              href="/userprofile"
              title={t("yourProfile", {}, "Your Profile")}
            />
            <MenuButton href="/settings" title={t("settings", {}, "Settings")} />
          */}
          <MenuButton href="/sign-out" title={t("signOut", {}, "Sign Out")} />
        </div>
      </div>
    </DisclosurePanel>
  );
}

interface MenuButtonProps {
  href: string;
  title: string;
}

function MenuButton(props: MenuButtonProps) {
  return (
    <DisclosureButton
      as="a"
      href={props.href}
      class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
    >
      {props.title}
    </DisclosureButton>
  );
}

interface NavButtonProps {
  href: string;
  title: string;
}

function NavButton(props: NavButtonProps) {
  return (
    <DisclosureButton as="div">
      <NavLink
        activeClass="bg-indigo-50 border-indigo-500 text-indigo-700"
        class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
        href={props.href}
        inactiveClass="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
      >
        {props.title}
      </NavLink>
    </DisclosureButton>
  );
}

interface LanguageSelectorProps {
  setLanguage: Setter<string>;
}

function LanguageSelector(props: LanguageSelectorProps) {
  const [, { locale }] = useI18n();

  return (
    <button
      type="button"
      class="ml-auto mr-3 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 w-6 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      onClick={() => {
        console.log(locale());
        if (locale() === "en") {
          props.setLanguage("se");
          locale("se");
        } else {
          props.setLanguage("en");
          locale("en");
        }
      }}
    >
      <Switch fallback={"🇸🇪"}>
        <Match when={locale && locale() === "en"}>🇺🇸</Match>
      </Switch>
    </button>
  );
}

function NotificationButton() {
  return (
    <button
      type="button"
      class="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    >
      <span class="sr-only">View notifications</span>
      <BellIcon />
    </button>
  );
}

interface UserAvatarProps {
  class: string;
  user: User | undefined;
}

function UserAvatar(props: UserAvatarProps) {
  const [gravatarHash, setGravatarHash] = createSignal<string>();

  createEffect(() => {
    if (!props.user || !props.user.email) {
      setGravatarHash();
      return;
    }
    const hash = Md5.hashStr(props.user.email.trim().toLowerCase());
    setGravatarHash(hash);
  });

  return (
    <img
      class={props.class}
      src={`https://www.gravatar.com/avatar/${gravatarHash()}?d=retro&s=200`}
      alt=""
    />
  );
}

interface UserInfoProps {
  user: User | undefined;
}

function UserInfo(props: UserInfoProps) {
  return (
    <>
      <div class="flex-shrink-0">
        <UserAvatar class="h-10 w-10 rounded-full" user={props.user} />
      </div>
      <div class="ml-3">
        <div class="text-base font-medium text-gray-800">
          {props?.user?.userName ?? "n/a"}
        </div>
        <div class="text-sm font-medium text-gray-500">
          {props?.user?.email ?? "n/a"}
        </div>
      </div>
    </>
  );
}

interface UserProfileMenuProps {
  isOpen: boolean;
  isOpenSetter: Setter<boolean>;
  user: User | undefined;
}

function UserProfileMenu(props: UserProfileMenuProps) {
  const [t] = useI18n();

  return (
    <Popover as="div" class="flex ml-3" defaultOpen={false}>
      {({ isOpen }) => (
        <>
          <PopoverButton class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="sr-only">Open user menu</span>
            <UserAvatar class="h-8 w-8 rounded-full" user={props.user} />
          </PopoverButton>

          <Transition
            as="div"
            enter="transition ease-out duration-500"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={isOpen()}
          >
            <PopoverPanel unmount={false}>
              <Menu class="fixed z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {/*
                  <MenuItem>
                    <Link
                      classList={{
                        "bg-gray-100": true,
                        "block px-4 py-2 text-sm text-gray-700": true,
                        "focus:outline-none hover:bg-nl-gray-400": true,
                      }}
                      href="/userprofile"
                    >
                      {t("yourProfile", {}, "Your Profile")}
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      classList={{
                        "bg-gray-100": true,
                        "block px-4 py-2 text-sm text-gray-700": true,
                        "focus:outline-none hover:bg-nl-gray-400": true,
                      }}
                      href="/settings"
                    >
                      {t("settings", {}, "Settings")}
                    </Link>
                  </MenuItem>
                  <hr />
                */}
                <MenuItem>
                  <Link
                    classList={{
                      "bg-gray-100": true,
                      "block px-4 py-2 text-sm text-gray-700": true,
                      "focus:outline-none hover:bg-nl-gray-400": true,
                    }}
                    href="/sign-out"
                  >
                    {t("signOut", {}, "Sign Out")}
                  </Link>
                </MenuItem>
              </Menu>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );

  /*
  return (
    <Menu as="div" class="flex ml-3">
      <div>
        <Toggle
          class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          defaultPressed={false}
          onChange={(x) => props.isOpenSetter(x)}
        >
          <span class="sr-only">Open user menu</span>
          <UserAvatar class="h-8 w-8 rounded-full" user={props.user} />
        </Toggle>
      </div>

      <Transition
        as="div"
        enter="transition ease-out duration-500"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={props.isOpen}
      >
        <div class="fixed z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem>
            <Link
              classList={{
                "bg-gray-100": true,
                "block px-4 py-2 text-sm text-gray-700": true,
                "hover:bg-nl-gray-400": true,
              }}
              href="/userprofile"
            >
              {t("yourProfile", {}, "Your Profile")}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              classList={{
                "bg-gray-100": true,
                "block px-4 py-2 text-sm text-gray-700": true,
                "hover:bg-nl-gray-400": true,
              }}
              href="/settings"
            >
              {t("settings", {}, "Settings")}
            </Link>
          </MenuItem>
          <hr />
          <MenuItem>
            <Link
              classList={{
                "bg-gray-100": true,
                "block px-4 py-2 text-sm text-gray-700": true,
                "hover:bg-nl-gray-400": true,
              }}
              href="/sign-out"
            >
              {t("signOut", {}, "Sign Out")}
            </Link>
          </MenuItem>
        </div>
      </Transition>
    </Menu>
  );
  */
}

interface HeaderProps {
  setLanguage: Setter<string>;
}

export function Header(props: HeaderProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = createSignal(false);
  // const [user, setUser] = createSignal<string>();
  const [t] = useI18n();
  const user = useContext(UserContext);

  return (
    <header>
      <Show when={user?.accessToken}>
        <Disclosure
          as="nav"
          class="border-b border-gray-300 bg-white flex"
          defaultOpen
        >
          {({ isOpen }) => (
            <>
              <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="flex h-16 justify-between">
                  <div class="flex flex-shrink-0 items-center">
                    <Logo />
                  </div>

                  <div class="flex">
                    <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8 justify-end">
                      {/* FIXME aria-current={item.current ? "page" : undefined} */}
                      <NavLink
                        activeClass="border-indigo-500 text-gray-900"
                        class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        href="/dashboard"
                        inactiveClass="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      >
                        {t("dashboard", {}, "Dashboard")}
                      </NavLink>
                      <NavLink
                        activeClass="border-indigo-500 text-gray-900"
                        class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                        href="/annotation"
                        inactiveClass="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      >
                        {t("annotation", {}, "Annotation")}
                      </NavLink>
                      <Show when={user?.group === "admin"}>
                        <NavLink
                          activeClass="border-indigo-500 text-gray-900"
                          class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          href="/service"
                          inactiveClass="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          {t("service", {}, "Service")}
                        </NavLink>
                      </Show>

                      {/* <NavLink
                          activeClass="border-indigo-500 text-gray-900"
                          class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                          href="/statistics"
                          inactiveClass="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        >
                          {t("statistics", {}, "Statistics")}
                        </NavLink> */}
                    </div>
                    <div class="hidden sm:ml-6 sm:flex sm:items-center">
                      <LanguageSelector setLanguage={props.setLanguage} />
                      {/*
                        <button
                          type="button"
                          class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span class="sr-only">View notifications</span>
                          <BellIcon />
                        </button>
                      */}

                      <UserProfileMenu
                        isOpen={isMenuOpen()}
                        isOpenSetter={setIsMenuOpen}
                        user={user}
                      />
                    </div>
                  </div>

                  <div class="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <DisclosureButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="sr-only">Open main menu</span>
                      <Show when={isOpen()} fallback={<Bars3Icon />}>
                        <XMarkIcon />
                      </Show>
                    </DisclosureButton>
                  </div>
                </div>
              </div>

              {/* Navigation that is hidden on narrow window */}
              <HeaderNavRow user={user} />
            </>
          )}
        </Disclosure>
      </Show>
    </header>
  );
}
