import {
  Accessor,
  For,
  Setter,
  Show,
  createEffect,
  createSignal,
} from "solid-js";
import {
  AnnotationTypes,
  DatasetList,
  DatasetListCompressed,
  SingleDatasetType,
} from "../../../helpers/types";
import { CopyIcon } from "../../../components/Icons";
import ClassLabelOverview from "./ClassLabelOverview";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import {
  _CreateClassLabel,
  _DeleteClassLabel,
  _UpdateClassLabel,
  _UpdateDataset,
} from "../../../helpers/mutations";
import UpdateConfirmationBox from "../../../components/UpdateConfirmationBox";

const _emptyClassLabel = {
  color: "",
  name: "",
  id: "",
  modelClassId: "",
};

interface ConfigDatasetProps {
  getSingleDataset: Accessor<SingleDatasetType>;
  setSingleDataset: Setter<SingleDatasetType>;
  refetchDatasets: (
    info?: unknown,
  ) => DatasetList | Promise<DatasetList | undefined> | null | undefined;
  changesMade: Accessor<boolean>;
  setChangesMade: Setter<boolean>;
  showOverview: Setter<boolean>;
  client: GraphQLClientQuery;
}

export default function ConfigDatasetOverview(props: ConfigDatasetProps) {
  var nameInput!: HTMLInputElement;
  var annotationSelector!: HTMLSelectElement;
  var classSelector!: HTMLSelectElement;

  const [getClassLabel, setClassLabel] = createSignal(_emptyClassLabel);

  //For confirmationsboxes and overviews
  const [showClassLabelOverview, setShowClassLabelOverview] =
    createSignal(false);
  const [showUpdateConfirmationBox, setShowUpdateConfirmationBox] =
    createSignal(false);

  //For keeping track of changes
  const [nameIsChanged, setNameIsChanged] = createSignal<boolean>(false);
  const [annotationTypeIsChanged, setAnnotationTypeIsChanged] =
    createSignal<boolean>(false);
  const [selectedAnnotationType, setSelectedAnnotationType] = createSignal(props.getSingleDataset().annotationType.toString()); 

  //Set to false as no to fire on mount
  const [updateQueryInput, setUpdateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [updateDatasetResponse] = props.client(
    _UpdateDataset,
    updateQueryInput,
  );

  //Subrscribers to changes
  createEffect(() => {
    if (
      !updateDatasetResponse.loading &&
      !updateDatasetResponse.error &&
      updateDatasetResponse()
    ) {
      {
        console.log("Dataset update successful. Updating list");
        props.refetchDatasets();
      }
    }
  });

  createEffect(() => {
    if (nameIsChanged() || annotationTypeIsChanged()) {
      props.setChangesMade(true);
    } else {
      props.setChangesMade(false);
    }
  });

  // Handlers and functions
  const nameInputHandler = () => {
    if (nameInput.value !== props.getSingleDataset().name) {
      setNameIsChanged(true);
    }
    if (
      nameInput.value === props.getSingleDataset().name ||
      nameInput.value === ""
    ) {
      setNameIsChanged(false);
    }
  };

  const selectAnnotationTypeHandler = () => {
    if (annotationSelector.value !== props.getSingleDataset().annotationType) {
      setAnnotationTypeIsChanged(true);
      setSelectedAnnotationType(annotationSelector.value);
    }
    if (
      annotationSelector.value === props.getSingleDataset().annotationType ||
      annotationSelector.selectedIndex === 0
    ) {
      setAnnotationTypeIsChanged(false);
      setSelectedAnnotationType(annotationSelector.value);
    }
  };

  const selectClassHandler = (id: string) => {
    if (id === "NEW") {
      setClassLabel(_emptyClassLabel);
      setShowClassLabelOverview(true);
      return;
    }
    if (classSelector.selectedIndex === 0) {
      setClassLabel(_emptyClassLabel);
      setShowClassLabelOverview(false);
      return;
    }

    const currentClassLabel = props
      .getSingleDataset()
      .classes.items.find((label) => label.id === id);

    if (currentClassLabel !== undefined) {
      setClassLabel(currentClassLabel);
      setShowClassLabelOverview(true);
    }
  };

  const closeClassLabelOverview = () => {
    setClassLabel(_emptyClassLabel);
    setShowClassLabelOverview(false);
    classSelector.selectedIndex = 0;
  };

  const updateDatasetFunction = () => {
    var input = {
      updateDatasetId: props.getSingleDataset().id,
      input: {},
    };

    if (nameIsChanged()) {
      input.input = { name: nameInput.value };
    }
    if (annotationTypeIsChanged()) {
      input.input = {
        ...input.input,
        annotationType: annotationSelector.value,
      };
    }

    setUpdateQueryInput(input);
    window.location.href = "#datasetoverview";
  };

  const sortedClassLabelList = () => {
    return props.getSingleDataset()?.classes.items.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const sortedModelsList = () => {
    return props.getSingleDataset()?.models.items.sort((a, b) => {
      if (a.Model.createdAt < b.Model.createdAt) {
        return -1;
      } else if (a.Model.createdAt > b.Model.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <div
      class="mx-auto my-10 w-10/12 bg-white shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm"
      id="singledatasetoverview"
    >
      <div>
        <h2 class="px-4 pb-2 pt-4 text-xl font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 ">
          {props.getSingleDataset().name}
        </h2>
      </div>
      <Show when={updateDatasetResponse.error}>
        <p class="block text-sm font-medium text-red-700 mt-10 ml-10">
          *Something went wrong, could not update. Try again or reload page.
        </p>
      </Show>
      <div class="flex flex-row">
        <div class="w-1/2 p-5">
          <label
            for="datasetName"
            class="block font-medium text-gray-700 px-5 mt-5 mb-1"
          >
            Name
          </label>
          <input
            class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
            classList={{
              "border-2 border-green-400 shadow-sm": nameIsChanged(),
            }}
            id="datasetName"
            type="text"
            ref={nameInput}
            placeholder={props.getSingleDataset().name}
            onInput={() => nameInputHandler()}
          />
          <label
            for="annotationType"
            class="block font-medium text-gray-700 px-5 mt-7 mb-1"
          >
            AnnotationType
          </label>
          <select
            class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
            id="annotationType"
            ref={annotationSelector}
            onclick={() => selectAnnotationTypeHandler()}
          >
            <option
              value={props.getSingleDataset().annotationType}
              class="text-gray-500"
            >
              {props.getSingleDataset().annotationType} ✓
            </option>
            <For each={Object.values(AnnotationTypes)}>
              {(annotationType) => (
                <Show
                  when={
                    annotationType !== props.getSingleDataset().annotationType
                  }
                >
                  <option value={annotationType}>{annotationType}</option>
                </Show>
              )}
            </For>
          </select>
          <Show when={selectedAnnotationType() === AnnotationTypes.SingleLabel}>
            <label
              for="classLabels"
              class="px-5 mt-7 mb-1 block font-medium text-gray-700 self-start"
            >
              Connected classlabels
            </label>
            <select
              ref={classSelector}
              class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-400 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-500 sm:text-sm"
              id="classLabels"
              onChange={(event: any) => {
                selectClassHandler(event?.target.value);
              }}
            >
              <option />
              <Show when={props.getSingleDataset().classes.items.length > 0}>
                <For each={sortedClassLabelList()}>
                  {(classLabel) => (
                    <option value={classLabel.id}>{classLabel.name}</option>
                  )}
                </For>
              </Show>
              <Show when={props.getSingleDataset().classes.items.length === 0}>
                <option selected>No current labels registred</option>
              </Show>
              <option value="NEW" class="text-green-300 font-bold">
                NEW LABEL
              </option>
            </select>
            <Show when={showClassLabelOverview()}>
              <ClassLabelOverview
                getClassLabel={getClassLabel}
                datasetId={props.getSingleDataset().id}
                refetchDatasets={props.refetchDatasets}
                closeOverview={closeClassLabelOverview}
                client={props.client}
              />
            </Show>
          </Show>
        </div>

        <div class="w-1/2 p-5">
          <div class="my-5">
            <h3 class="px-5">Details</h3>
            <div class="block text-sm px-5 mt-4 mb-1">
              <p class=" font-medium text-gray-700">ID</p>
              <div class="flex flex-row items-center">
                <p class="text-gray-500">{props.getSingleDataset().id} </p>
                <a
                  onClick={() =>
                    navigator.clipboard.writeText(props.getSingleDataset().id)
                  }
                  class="cursor-pointer active:animate-ping active:text-green-400 m-1"
                >
                  <CopyIcon />
                </a>
              </div>
            </div>
            <div class="block text-sm my-4 mx-5">
              <p class=" font-medium text-gray-700">Created</p>
              <p class="text-gray-500">
                {new Date(
                  props.getSingleDataset().createdAt,
                ).toLocaleDateString("sv-SE")}{" "}
                |{" "}
                {new Date(
                  props.getSingleDataset().createdAt,
                ).toLocaleTimeString("sv-SE")}{" "}
              </p>
            </div>
            <div class="block text-sm my-4 mx-5">
              <p class=" font-medium text-gray-700">Last updated</p>
              <p class="text-gray-500">
                {new Date(
                  props.getSingleDataset().updatedAt,
                ).toLocaleDateString("sv-SE")}{" "}
                |{" "}
                {new Date(
                  props.getSingleDataset().updatedAt,
                ).toLocaleTimeString("sv-SE")}
              </p>
            </div>
            <div class="block text-sm my-4 mx-5">
              <p class=" font-medium text-gray-700">Images</p>
              <p class="text-gray-500">
                {props.getSingleDataset().images.items.length}
              </p>
            </div>
            <h5 class="px-5 mt-5 mb-1 block font-medium text-gray-700">
              Connected AI-models
            </h5>
            <Show when={props.getSingleDataset().models.items.length > 0}>
              <For each={sortedModelsList()}>
                {(model) => (
                  <div class="block text-sm my-4 mx-5">
                    <p class=" font-medium text-gray-700">{model.Model.name}</p>
                    <div class="flex flex-row items-center">
                      <p class="text-gray-500">{model.Model.id} </p>
                      <a
                        onClick={() =>
                          navigator.clipboard.writeText(model.Model.id)
                        }
                        class="cursor-pointer active:animate-ping active:text-green-400 m-1"
                      >
                        <CopyIcon />
                      </a>
                    </div>
                  </div>
                )}
              </For>
            </Show>{" "}
            <Show when={props.getSingleDataset().models.items.length === 0}>
              <div class="block text-sm my-4 mx-5">
                <p class=" font-medium text-gray-500">
                  No current models registred
                </p>
              </div>
            </Show>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-center p-5">
        <a
          class="nl-button nl-button--xs m-1 cursor-pointer"
          classList={{
            "opacity-100": props.changesMade(),
            "opacity-50 pointer-events-none": !props.changesMade(),
          }}
          onClick={() => {
            if (nameIsChanged() || annotationTypeIsChanged()) {
              setShowUpdateConfirmationBox(true);
            }
          }}
        >
          Update
        </a>
        <a
          class="nl-button nl-button--xs m-1  text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
          onClick={(event: MouseEvent) => {
            props.showOverview(false);
            window.location.href = "#datasetoverview";
          }}
        >
          Cancel
        </a>
      </div>
      <Show when={showUpdateConfirmationBox()}>
        <UpdateConfirmationBox
          showDialog={setShowUpdateConfirmationBox}
          updateFunction={updateDatasetFunction}
          nameToUpdate={props.getSingleDataset().name}
        ></UpdateConfirmationBox>
      </Show>
    </div>
  );
}
