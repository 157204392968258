import { Show } from "solid-js";
import { CopyIcon } from "../../../components/Icons";

interface DatasetCardProps {
  name: string;
  id: string;
  updatedAt: string;
  images: number;
}

export default function DatasetCard(props: DatasetCardProps) {  

  return (
    <div class="border border-gray-300 rounded-md p-5 mx-5 mt-5">
      <p class="font-medium text-gray-700 mb-2 text-center">{props.name}</p>

      <p class=" font-medium text-gray-700 text-sm">ID</p>
      <div class="flex flex-row items-center">
        <p class="text-gray-500 text-sm">{props.id} </p>
        <a
          onClick={() => navigator.clipboard.writeText(props.id)}
          class="cursor-pointer active:animate-ping active:text-green-400 m-1"
        >
          <CopyIcon />
        </a>
      </div>
      <div class="block text-sm my-2">
        <p class=" font-medium text-gray-700">Last updated</p>
        <p class="text-gray-500">
          <Show when={props.updatedAt !== undefined}>
            <p class="text-gray-500 text-sm">
              {new Date(props.updatedAt).toLocaleDateString("sv-SE")} |{" "}
              {new Date(props.updatedAt).toLocaleTimeString("sv-SE")}{" "}
            </p>
          </Show>
        </p>
      </div>
      <p class=" font-medium text-gray-700 text-sm">Images</p>
      <div class="flex flex-row items-center">
        <Show when={props.images !== undefined}>
          <p class="text-gray-500 text-sm">{props.images}</p>
        </Show>
        <Show when={props.images === undefined}>
          <p class="text-gray-500 text-sm">0</p>
        </Show>
      </div>
    </div>
  );
}
