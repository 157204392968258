import {
  Accessor,
  For,
  Match,
  Setter,
  Show,
  Switch,
  createEffect,
  createSignal,
} from "solid-js";
import { EntityTypes, SourceList, SourceListCompressed } from "../../../helpers/types";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import { useI18n } from "@solid-primitives/i18n";
import { CopyIcon, SpinnerIcon } from "../../../components/Icons";
import { _DeleteSource, _UpdateSource } from "../../../helpers/mutations";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";

interface SourceListProps {
  getSourcesList: Accessor<SourceListCompressed | undefined>;
  setSourcesList: Setter<SourceListCompressed | undefined>;
  refetchSources: (
    info?: unknown,
  ) => SourceList | Promise<SourceList | undefined> | null | undefined;
  client: GraphQLClientQuery;
}

export default function SourceOverview(props: SourceListProps) {
  const [t] = useI18n();
  var nameInput!: HTMLInputElement;

  //For confirmationboxes
  const [showDeleteConfirmationBox, setShowDeleteConfirmationBox] =
    createSignal(false);

  //For keeping track of changes
  const [changeNameId, setChangeNameId] = createSignal("");
  const [sourceToDeleteId, setSourceToDeleteId] = createSignal<string>("");
  const [sourceToDeleteName, setSourceToDeleteName] = createSignal<string>("");
  const [nameIsChanged, setNameIsChanged] = createSignal<boolean>(false);

  //Set to false as not to fire on mount
  const [deleteQueryInput, setDeleteQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [deleteSourceResponse] = props.client<boolean>(
    _DeleteSource,
    deleteQueryInput,
  );

  const [updateQueryInput, setUpdateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [updateSourceResponse] = props.client<boolean>(
    _UpdateSource,
    updateQueryInput,
  );

  //Subscribers to changes
  createEffect(() => {
    if (
      !deleteSourceResponse.loading &&
      !deleteSourceResponse.error &&
      deleteSourceResponse()
    ) {
      console.log("Delete successful. Updating list");
      props.refetchSources();
    }
  });

  createEffect(() => {
    if (
      !updateSourceResponse.loading &&
      !updateSourceResponse.error &&
      updateSourceResponse()
    ) {
      {
        console.log("Source update successful. Updating list");
        setNameIsChanged(false);
        setUpdateQueryInput(false);
        props.refetchSources();
      }
    }
  });

  //Functions and handlers
  const nameInputHandler = () => {
    if (nameInput.value !== nameInput.placeholder) {
      setNameIsChanged(true);
    }
    if (nameInput.value === nameInput.placeholder || nameInput.value === "") {
      setNameIsChanged(false);
    }
  };

  const deleteSourceHandler = (input: { id: string; name: string }) => {
    setSourceToDeleteId(input.id);
    setSourceToDeleteName(input.name);
    setShowDeleteConfirmationBox(true);
  };

  const deleteSourceFunction = (id: string) => {
    const input = { input: { id: id } };
    setDeleteQueryInput(input);
    window.location.href = "#sourcesoverview";
  };

  const updateSourceFunction = (id: string) => {
    if (nameIsChanged()) {
      const updateSourceId = id;
      const input = { name: nameInput.value };
      setUpdateQueryInput({ updateSourceId, input });
      window.location.href = "#sourcesoverview";
    }
  };

  const sortedSourcesList = () => {
    return props.getSourcesList()?.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  return (
    <>
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="shadow-sm ring-1 ring-black ring-opacity-5">
          <table
            class="min-w-full border-separate"
            style={{
              "border-spacing": "0",
            }}
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  {t("name", {}, "Name")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("created at", {}, "Created at")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                >
                  {t("id", {}, "ID")}
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                />
              </tr>
            </thead>
            <tbody class="bg-white">
              <Switch
                fallback={
                  <For each={sortedSourcesList()}>
                    {(source) => (
                      <>
                        <tr>
                          <td class="whitespace-nowrap w-3/12 border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                            {source.name}
                          </td>
                          <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                            {new Date(source.createdAt).toLocaleDateString(
                              "sv-SE",
                            )}{" "}
                            {new Date(source.updatedAt).toLocaleTimeString(
                              "sv-SE",
                            )}
                          </td>
                          <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                            {source.id}
                            <a
                              onClick={() =>
                                navigator.clipboard.writeText(source.id)
                              }
                              class="cursor-pointer active:animate-ping text-black active:text-green-400 m-1"
                            >
                              <CopyIcon />
                            </a>
                          </td>
                          <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <a
                              onClick={() => {
                                if (changeNameId() !== source.id) {
                                  setChangeNameId(source.id);
                                } else {
                                  setChangeNameId("");
                                }
                              }}
                              class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                            >
                              {t("config", {}, "Config")}
                            </a>
                          </td>
                          <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8 cursor-pointer">
                            <a
                              onClick={() =>
                                deleteSourceHandler({
                                  id: source.id,
                                  name: source.name,
                                })
                              }
                              class="text-red-600 hover:text-red-900 cursor-pointer"
                            >
                              {t("delete", {}, "Delete")}
                            </a>
                          </td>
                        </tr>
                        <Show when={source.id === changeNameId()}>
                          <tr>
                            <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                              <input
                                type="text"
                                ref={nameInput}
                                class="w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
                                placeholder={source.name}
                                onInput={() => nameInputHandler()}
                              />
                              <a
                                class="nl-button nl-button--xs m-1 cursor-pointer"
                                classList={{
                                  "opacity-100": nameIsChanged(),
                                  "opacity-50 pointer-events-none":
                                    !nameIsChanged(),
                                }}
                                onClick={() => updateSourceFunction(source.id)}
                              >
                                Save
                              </a>
                            </td>
                            <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
                              {" "}
                              <p class="font-medium text-gray-700 mb-1">
                                Last updated
                              </p>
                              <p class="text-gray-500">
                                {new Date(source.updatedAt).toLocaleDateString(
                                  "sv-SE",
                                )}{" "}
                                |{" "}
                                {new Date(source.updatedAt).toLocaleTimeString(
                                  "sv-SE",
                                )}
                              </p>
                            </td>
                            <td class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                              <a
                                onClick={() => {
                                  setChangeNameId("");
                                }}
                                class="text-gray-600 hover:text-gray-900 cursor-pointer"
                              >
                                {t("cancel", {}, "Cancel")}
                              </a>
                            </td>
                          </tr>
                        </Show>
                      </>
                    )}
                  </For>
                }
              >
                <Match when={props.getSourcesList()?.length === 0}>
                  <tr>
                    <td colspan="6">
                      <div class="mx-auto my-2 py-1 rounded-full text-center">
                        No current sources
                      </div>
                    </td>
                  </tr>
                </Match>
              </Switch>
            </tbody>
          </table>
        </div>
      </div>
      <Show when={deleteSourceResponse.error}>
        <p class="block text-sm font-medium text-red-700 m-2">
          *Something went wrong, could not delete. Try again or reload page.
        </p>
      </Show>
      <Show when={deleteSourceResponse.loading}>
        <div class="m-5">
          <SpinnerIcon />
          {t("deleting", {}, "Deleting")}...
        </div>
      </Show>
      <Show when={showDeleteConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteConfirmationBox}
          deleteFunction={deleteSourceFunction}
          nameToDelete={sourceToDeleteName()}
          idToDelete={sourceToDeleteId()}
          type={EntityTypes.source}
        />
      </Show>
    </>
  );
}
