import { Setter } from "solid-js";
import { _DeleteClassLabel } from "../helpers/mutations";

interface RevertConfirmationDialogProps {
  showDialog: Setter<boolean>;
  revertFunction: () => void;
  nameToRevert: string;
}

export default function RevertConfirmationBox(
  props: RevertConfirmationDialogProps,
) {
  return (
    <div class="fixed inset-0 z-40 min-h-full overflow-y-auto overflow-x-hidden transition flex items-center">
      <div
        aria-hidden="true"
        class="fixed inset-0 w-full h-full bg-black/50 cursor-pointer"
      ></div>

      <div class="relative w-full cursor-pointer pointer-events-none transition my-auto p-4">
        <div class="w-full py-2cursor-default pointer-events-auto bg-gray-100 relative rounded-xl mx-auto max-w-sm">
          <div class="space-y-2 p-2">
            <div class="p-4 space-y-2 text-center">
              <h2 class="text-xl font-bold tracking-tight">
                Revert changes to <br/>"{props.nameToRevert}"
              </h2>

              <p class="text-gray-600">
               Are you sure?
              </p>
            </div>
          </div>

          <div class="space-y-2">
            <div
              aria-hidden="true"
              class="border-t dark:border-gray-700 px-2"
            ></div>

            <div class="px-6 py-2">
              <div class="grid gap-2 grid-cols-[repeat(auto-fit,minmax(0,1fr))]">
                <a
                  class="inline-flex items-center justify-center py-1 gap-1 nl-button nl-button--xs m-1 bg-nl-yellow-600 hover:bg-nl-yellow-900 cursor-pointer "
                  onclick={() => {
                    props.revertFunction();
                    props.showDialog(false);
                  }}
                >
                  Revert
                </a>

                <a
                  class="inline-flex items-center justify-center py-1 gap-1 nl-button nl-button--xs m-1 bg-nl-gray-400 hover:bg-nl-gray-800 text-black cursor-pointer"
                  onClick={() => {
                    props.showDialog(false);
                  }}
                >
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
