import {
  createEffect,
  createSignal,
  ErrorBoundary,
  Show,
  Suspense,
  useContext,
  type JSX,
  type Setter,
} from "solid-js";
import { Navigate, Route, Routes, useLocation } from "@solidjs/router";
import { UserContext, UserProvider } from "./components/UserContext";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ForgotPasswordSubmitPage from "./pages/ForgotPasswordSubmit";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import AnnotateDatasetPage from "./pages/AnnotateDatasetPage";
import AnnotationPage from "./pages/Annotation";
import DashboardPage from "./pages/Dashboard";
import InspectionPage from "./pages/Inspection";
import StatisticsPage from "./pages/Statistics";
import { LandingPage } from "./pages/Landing";
import OuterErrorHandler from "./OuterErrorHandler";
import ServicePage from "./pages/Service";
import SettingsPage from "./pages/Settings";
import SignInPage from "./pages/SignIn";
import SignOutPage from "./pages/SignOut";
import SignUpPage from "./pages/SignUp";
import StartPage from "./pages/Start";
import UserProfilePage from "./pages/UserProfile";

interface AppProps {
  setLanguage: Setter<string>;
}

function ProtectedRoute({ children, requiredRole }: { children: JSX.Element, requiredRole?: string }) {
  const user = useContext(UserContext);

  if (!user?.accessToken || (requiredRole && user.group !== requiredRole)) {
    return <Navigate href="/dashboard" />;
  }

  return children; 
};

const App: (props: AppProps) => JSX.Element = (props: AppProps) => {
  const location = useLocation();
  const [fullscreen, setFullscreen] = createSignal<boolean>();

  createEffect(() => {
    if (location.pathname.match(/\/(annotation|inspection)\/[a-z0-9]/)) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  });

  return (
    <div
      classList={{
        "flex flex-col nl-gradient": true,
        "h-screen": fullscreen(),
        "min-h-screen justify-between": !fullscreen(),
      }}
    >
      <Suspense>
        <ErrorBoundary
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          fallback={(err: any, reset: () => void) => {
            console.error(err);
            return <OuterErrorHandler error={err} reset={reset} />;
          }}
        >
          <div
            classList={{
              "flex flex-col grow": fullscreen(),
            }}
          >
            <UserProvider>
              <Header setLanguage={props.setLanguage} />
              <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/annotation">
                  <Route path="/" element={<AnnotationPage />} />
                  <Route path="/:id" element={<AnnotateDatasetPage />} />
                </Route>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/inspection">
                  <Route path="/:id" element={<InspectionPage />} />
                </Route>
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/sign-in">
                  <Route path="/" element={<SignInPage />} />
                  <Show
                    when={
                      import.meta.env.VITE_ENABLE_FORGOT_PASSWORD === "true"
                    }
                  >
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route
                      path="/forgot-password-submit"
                      element={<ForgotPasswordSubmitPage />}
                    />
                  </Show>
                </Route>
                <Route path="/sign-out" element={<SignOutPage />} />
                <Show
                  when={import.meta.env.VITE_ENABLE_REGISTRATION === "true"}
                >
                  <Route path="/sign-up" element={<SignUpPage />} />
                </Show>
                <Route path="/service" element={
                  <ProtectedRoute requiredRole="admin"> <ServicePage /> </ProtectedRoute>}/>
                <Route path="/statistics" element={<StatisticsPage />} />
                <Route path="/userprofile" element={<UserProfilePage />} />
                <Route path="*" element={<Navigate href="/" />} />
              </Routes>
            </UserProvider>
          </div>
          <Show when={!fullscreen()}>
            <Footer />
          </Show>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

export default App;
