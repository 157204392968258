import {
  Accessor,
  For,
  Setter,
  Show,
  createEffect,
  createSignal,
} from "solid-js";
import {
  CreateDatasetUsedByModelType,
  DatasetList,
  DatasetListCompressed,
  EntityTypes,
  ModelsList,
  SingleDatasetUsedByModelType,
  SingleModelType,
} from "../../../helpers/types";
import { ChevronDoubleRight, CopyIcon } from "../../../components/Icons";
import { GraphQLClientQuery } from "@solid-primitives/graphql";
import { SetStoreFunction } from "solid-js/store";
import DatasetCard from "./DatasetCard";
import DeleteConfirmationBox from "../../../components/DeleteConfirmationBox";
import {
  _CreateDatasetsUsedByModel,
  _DeleteDatasetsUsedByModel,
  _UpdateModel,
} from "../../../helpers/mutations";
import UpdateConfirmationBox from "../../../components/UpdateConfirmationBox";

interface ConfigDatasetProps {
  getSingleModel: SingleModelType;
  setSingleModel: SetStoreFunction<SingleModelType>;
  modelId: Accessor<string>;
  refetchModels: (
    info?: unknown,
  ) => ModelsList | Promise<ModelsList | undefined> | null | undefined;
  refetchDatasets: (
    info?: unknown,
  ) => DatasetList | Promise<DatasetList | undefined> | null | undefined;
  getDatasetsList: Accessor<DatasetListCompressed | undefined>;
  changesMade: Accessor<boolean>;
  setChangesMade: Setter<boolean>;
  showOverview: Setter<boolean>;
  client: GraphQLClientQuery;
}

export default function ConfigModelOverview(props: ConfigDatasetProps) {
  var nameInput!: HTMLInputElement;
  var runtimeSelector!: HTMLSelectElement;
  var datasetSelector!: HTMLSelectElement;

  //For confirmationboxes
  const [showUpdateConfirmationBox, setShowUpdateConfirmationBox] =
    createSignal(false);
  const [
    showDeleteDatasetConnectionConfirmationBox,
    setShowDeleteDatasetConnectionConfirmationBox,
  ] = createSignal<boolean>(false);

  //For handling changes in datasetusedbymodel
  const [filteredDatasets, setFilteredDatasets] =
    createSignal<DatasetListCompressed>();
  const [datasetConnectionToDelete, setDatasetConnectionToDelete] =
    createSignal<SingleDatasetUsedByModelType>();

  //For keeping track of changes
  const [nameIsChanged, setNameIsChanged] = createSignal<boolean>(false);
  const [runtimeTypeIsChanged, setRuntimeTypeIsChanged] =
    createSignal<boolean>(false);
  const [changesMade, setChangesMade] = createSignal<boolean>(false);

  //Set to false as not to fire on mount
  const [updateQueryInput, setUpdateQueryInput] = createSignal<
    boolean | Object
  >(false);
  const [
    deleteDatasetConnectionQueryInput,
    setDeleteDalasetConnectionQueryInput,
  ] = createSignal<boolean | Object>(false);
  const [
    createDatasetConnectionQueryInput,
    setCreateDalasetConnectionQueryInput,
  ] = createSignal<boolean | Object>(false);

  const [createDatasetConnectionResponse] =
    props.client<CreateDatasetUsedByModelType>(
      _CreateDatasetsUsedByModel,
      createDatasetConnectionQueryInput,
    );
  const [updateModelResponse] = props.client(_UpdateModel, updateQueryInput);
  const [deleteDatasetConnectionsResponse] = props.client<boolean>(
    _DeleteDatasetsUsedByModel,
    deleteDatasetConnectionQueryInput,
  );

  //Subscribers to changes
  createEffect(() => {
    if (
      !createDatasetConnectionResponse.loading &&
      !createDatasetConnectionResponse.error &&
      createDatasetConnectionResponse()
    ) {
      console.log("Dataset connection successfully created.");
      const addedDataset =
        createDatasetConnectionResponse()?.createDatasetsUsedByModel;
      props.refetchDatasets();
      if (addedDataset !== undefined) {
        props.setSingleModel("datasets", "items", (items) => [
          ...items,
          addedDataset,
        ]);
      }
    }
  });

  createEffect(() => {
    if (
      !updateModelResponse.loading &&
      !updateModelResponse.error &&
      updateModelResponse()
    ) {
      {
        console.log("Model update successful. Updating list");
        props.refetchModels();
      }
    }
  });

  createEffect(() => {
    if (
      !deleteDatasetConnectionsResponse.loading &&
      !deleteDatasetConnectionsResponse.error &&
      deleteDatasetConnectionsResponse()
    ) {
      console.log("Dataset connection successfully deleted.");
      props.refetchDatasets();
      props.setSingleModel("datasets", "items", (items) =>
        items.filter((item) => item.id !== datasetConnectionToDelete()?.id),
      );
    }
  });

  createEffect(() => {
    if (props.modelId()) {
      nameInput.value = "";
      runtimeSelector.selectedIndex = 0;
      setNameIsChanged(false);
      setRuntimeTypeIsChanged(false);
    }
  });

  createEffect(() => {
    if (nameIsChanged() || runtimeTypeIsChanged()) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  });

  const connectionDontExist = (id: string): boolean => {
    if (
      props.getSingleModel.datasets.items.some(
        (dataset) => dataset.Dataset.id === id,
      )
    ) {
      return false;
    }
    return true;
  };

  createEffect(() => {
    setFilteredDatasets(
      props
        .getDatasetsList()
        ?.filter((dataset) => connectionDontExist(dataset.id)),
    );
  });

  //Handlers and functions
  const nameInputHandler = () => {
    if (nameInput.value !== props.getSingleModel.name) {
      setNameIsChanged(true);
    }
    if (
      nameInput.value === props.getSingleModel.name ||
      nameInput.value === ""
    ) {
      setNameIsChanged(false);
    }
  };

  const createDatasetConnectionFunction = () => {
    if (
      datasetSelector.value !== null &&
      connectionDontExist(datasetSelector.value)
    ) {
      const input = {
        input: {
          DatasetsUsedByModel_DatasetID: datasetSelector.value,
          DatasetsUsedByModel_ModelID: props.getSingleModel.id,
        },
      };
      setCreateDalasetConnectionQueryInput(input);
    }
  };

  const updateModelFunction = () => {
    var input = {
      updateModelId: props.getSingleModel.id,
      input: {},
    };

    if (nameIsChanged()) {
      input.input = { name: nameInput.value };
    }
    if (runtimeTypeIsChanged()) {
      input.input = {
        ...input.input,
        runtime: runtimeSelector.value,
      };
    }
    setUpdateQueryInput(input);
    window.location.href = "#modeloverview";
  };

  const deleteDatasetConnectionFunction = () => {
    if (datasetConnectionToDelete()?.id !== "") {
      const input = { input: { id: datasetConnectionToDelete()?.id } };
      console.log(input);
      setDeleteDalasetConnectionQueryInput(input);
      setShowDeleteDatasetConnectionConfirmationBox(false);
    }
  };

  return (
    <div
      class="mx-auto my-10 w-10/12 bg-white shadow-sm ring-1 ring-black ring-opacity-5 rounded-sm"
      id="singlemodeloverview"
    >
      <div>
        <h2 class="px-4 pb-2 pt-4 text-xl font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 ">
          {props.getSingleModel.name}
        </h2>
      </div>
      <Show when={updateModelResponse.error}>
        <p class="block text-sm font-medium text-red-700 mt-10 ml-10">
          *Something went wrong, could not update. Try again or reload page.
        </p>
      </Show>
      <div class="flex flex-row">
        <div class="w-1/2 p-5">
          <label
            for="datasetName"
            class="block font-medium text-gray-700 px-5 mt-5 mb-1"
          >
            Name
          </label>
          <input
            class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
            id="datasetName"
            type="text"
            ref={nameInput}
            placeholder={props.getSingleModel.name}
            onInput={() => nameInputHandler()}
          />
          <label
            for="runtimeTypes"
            class="block font-medium text-gray-700 px-5 mt-5 mb-1"
          >
            Runtime
          </label>
          <select
            class="block w-10/12 appearance-none rounded-md border border-gray-300 mx-5 my-2 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm m-2"
            id="runtimeTypes"
            ref={runtimeSelector}
            onChange={() => {
              if (runtimeSelector.value !== props.getSingleModel.runtime) {
                setRuntimeTypeIsChanged(true);
              }
            }}
          >
            <option value={props.getSingleModel.runtime}>
              {props.getSingleModel.runtime}
            </option>
          </select>
        </div>

        <div class="w-1/2 p-5">
          <div class="my-5">
            <h5 class="mx-5 block font-medium text-gray-700">Details</h5>
            <div class="mx-5 mt-3">
              <div class="block text-sm">
                <p class=" font-medium text-gray-700">ID</p>
                <div class="flex flex-row items-center">
                  <p class="text-gray-500">{props.getSingleModel.id} </p>
                  <a
                    onClick={() =>
                      navigator.clipboard.writeText(props.getSingleModel.id)
                    }
                    class="cursor-pointer active:animate-ping active:text-green-400 mx-1"
                  >
                    <CopyIcon />
                  </a>
                </div>
              </div>
              <div class="block text-sm my-2">
                <p class=" font-medium text-gray-700">Created</p>
                <p class="text-gray-500">
                  {new Date(props.getSingleModel.createdAt).toLocaleDateString(
                    "sv-SE",
                  )}{" "}
                  |{" "}
                  {new Date(props.getSingleModel.createdAt).toLocaleTimeString(
                    "sv-SE",
                  )}{" "}
                </p>
              </div>
              <div class="block text-sm my-2">
                <p class=" font-medium text-gray-700">Last updated</p>
                <p class="text-gray-500">
                  {new Date(props.getSingleModel.updatedAt).toLocaleDateString(
                    "sv-SE",
                  )}{" "}
                  |{" "}
                  {new Date(props.getSingleModel.updatedAt).toLocaleTimeString(
                    "sv-SE",
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-5">
        <div class="flex flex-row">
          <h5 class="block ml-5 mr-3 my-auto text-medium font-medium text-gray-700">
            Datasets used by {props.getSingleModel.name}
          </h5>
          <ChevronDoubleRight />
        </div>
        <div class="grid grid-cols-2">
          <For each={props.getSingleModel.datasets.items}>
            {(dataset) => (
              <div class="flex flex-col">
                <DatasetCard
                  name={dataset.Dataset.name}
                  id={dataset.Dataset.id}
                  updatedAt={dataset.Dataset.updatedAt}
                  images={dataset.Dataset.images.items.length}
                ></DatasetCard>
                <a
                  class="nl-button nl-button--xs bg-nl-red-400 hover:bg-nl-red-800 cursor-pointer w-fit mx-auto mt-2"
                  onClick={() => {
                    setDatasetConnectionToDelete(dataset);
                    setShowDeleteDatasetConnectionConfirmationBox(true);
                  }}
                >
                  Remove
                </a>
              </div>
            )}
          </For>
        </div>
        <Show when={props.getSingleModel.datasets.items.length === 0}>
          <div class="block text-sm my-4 mx-5">
            <p class=" font-medium text-gray-500">
              No current datasets used by model
            </p>
          </div>
        </Show>
        <div class="w-1/2 p-5 mt-5">
          <label
            for="addDatasetToModel"
            class="block font-medium text-gray-700"
          >
            Add dataset to Model
          </label>
          <select
            class="block w-10/12 mt-2 appearance-none rounded-md border border-gray-300 placeholder-gray-500 shadow-sm focus:border-nl-blue-royal-500 focus:outline-none focus:ring-nl-blue-royal-400 sm:text-sm"
            id="addDatasetToModel"
            ref={datasetSelector}
            onChange={() => createDatasetConnectionFunction()}
          >
            <option />
            <For each={filteredDatasets()}>
              {(dataset) => <option value={dataset.id}>{dataset.name}</option>}
            </For>
            <Show when={filteredDatasets()?.length === 0}>
              <option>All datasets connected</option>
            </Show>
          </select>
        </div>
      </div>{" "}
      <div class="flex flex-row justify-center p-5">
        <a
          class="nl-button nl-button--xs m-1 cursor-pointer"
          classList={{
            "opacity-100": changesMade(),
            "opacity-50 pointer-events-none": !changesMade(),
          }}
          onClick={() => {
            if (nameIsChanged() || runtimeTypeIsChanged()) {
              setShowUpdateConfirmationBox(true);
            }
          }}
        >
          Update
        </a>
        <a
          class="nl-button nl-button--xs m-1  text-black bg-nl-gray-400 hover:bg-nl-gray-800 cursor-pointer"
          onClick={(event: MouseEvent) => {
            event.preventDefault();
            props.setChangesMade(false);
            props.showOverview(false);
            props.refetchModels();
            props.refetchDatasets();
            window.location.href = "#modeloverview";
          }}
        >
          Close
        </a>
      </div>
      <Show when={showUpdateConfirmationBox()}>
        <UpdateConfirmationBox
          showDialog={setShowUpdateConfirmationBox}
          updateFunction={updateModelFunction}
          nameToUpdate={props.getSingleModel.name}
        />
      </Show>
      <Show when={showDeleteDatasetConnectionConfirmationBox()}>
        <DeleteConfirmationBox
          showDialog={setShowDeleteDatasetConnectionConfirmationBox}
          deleteFunction={deleteDatasetConnectionFunction}
          nameToDelete={
            props.getSingleModel.name +
              "/" +
              datasetConnectionToDelete()?.Dataset.name +
              " - connection" || ""
          }
          idToDelete={datasetConnectionToDelete()?.id || ""}
          type={EntityTypes.datasetUsedByModel}
        />
      </Show>
    </div>
  );
}
