/* @refresh reload */
import { Amplify } from "aws-amplify";
import { type Component, type JSX } from "solid-js";
import { render } from "solid-js/web"; // eslint-disable-line import/named
import { Router } from "@solidjs/router";
import { getAmplifyConfig } from "./utils/amplify";
import { I18nContext, createI18nContext } from "@solid-primitives/i18n";
import { cookieStorage, createStorageSignal } from "@solid-primitives/storage";
import App from "./App";
import "./index.css";

Amplify.configure(getAmplifyConfig());

const I18nProvider: Component<{
  children: JSX.Element;
  dict?: Record<string, Record<string, any>>;
  locale?: string;
}> = (props) => {
  const value = createI18nContext(props.dict, props.locale);
  return (
    <I18nContext.Provider value={value}>{props.children}</I18nContext.Provider>
  );
};

const expires = new Date();
expires.setDate(expires.getDate() + 60);

const cookieStoreOptions = {
  api: cookieStorage,
  deserializer: (value: string) => JSON.parse(value),
  options: {
    expires: expires,
    path: "/",
  },
  serializer: (value: string) => JSON.stringify(value),
};

//Outside createRoot or render
const [language, setLanguage] = createStorageSignal(
  "lang",
  "en",
  cookieStoreOptions,
);

const dict = {
  se: {
    aiClassifiedThisAs: "AI klassificerade som",
    agree: "Instämmer",
    annotate: "Annotera",
    annotation: "Annotering",
    annotationTask: "Annoteringsuppgift",
    box: "Låd-etikett",
    config: "Konfiguration",
    dashboard: "Översikt",
    datasetsToAnnotate: "Dataset att annotera",
    delete: "Radera",
    details: "Detaljer",
    disagree: "Instämmer inte",
    flagForReview: "Flagga för genomgång",
    inspect: "Inspektera",
    inspection: "Inspektion",
    last60mins: "Senaste 60 min",
    loading: "Laddar",
    measurement: "Mätvärde",
    name: "Namn",
    nDefects: "# Defekter",
    newInspection: "Ny inspektion",
    nOK: "# OK",
    nUnclassified: "# Oklassificerade",
    overriddenClassification: "Överriden klassifikation",
    overrideClassification: "Överrid klassifikation",
    settings: "Inställningar",
    signOut: "Logga ut",
    singleLabel: "Enkel-etikett",
    startInspection: "Starta inspektion",
    service: "Service",
    statistics: "Statistik",
    type: "Typ",
    yourProfile: "Din profil",
  },
};

render(
  () => (
    <I18nProvider dict={dict} locale={language() ?? "en"}>
      <Router>
        <App setLanguage={setLanguage} />
      </Router>
    </I18nProvider>
  ),
  document.getElementById("app") as HTMLElement,
);
